<template>
    <section>
        <HeaderCustom isHome='true'></HeaderCustom>
        <div class="container">
          <!-- sınıfa göre kapak öğrenci sayfası için -->
          <GradePhotos></GradePhotos> 
           <!-- summer learning için aç kapa -->

        <div :class="$style.header">
          <div class="row" :class="$style.columnsCustom">
            <div class="col-md-12 col-sm-12 mt-1">
              <!-- <Chart v-if="isTeacher"></Chart> -->
             
              <Homeworks v-if="isStudent" :lmsLoading='this.ready' :googleLoading='$store.state.googleReady'></Homeworks>
            </div>
          </div>
        </div>
        <br>
         <div v-if="ready && isTeacher && level != 'Lise'" class="row">
                <!-- <div v-for="(item,i) in interactiveReaders.filter(x => x.parent !== 0)" :key="i" class="col-md-6 mt-2"> -->
                  <div class="col-md-6 mt-2">
                      <router-link :to="{ path: 'scorms/532?page=1'}">
                          <div :class="$style.divPlatform">
                            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnInteractiveIC]"></div>
                          </div>
                      </router-link>
                  </div>
                  
                  <div class="col-md-6 mt-2">
                      <router-link :to="{ path: 'scorms/533?page=1'}">
                          <div :class="$style.divPlatform">
                            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnInteractiveSS]"></div>
                          </div>
                      </router-link>
                  </div>
                      
                <!-- </div> -->
          </div>
         <InnerLoading v-if="!ready"></InnerLoading>
         
         <Platforms :isStudent="isStudent" :isHome="true" v-else-if="closeMessage === null"></Platforms>
         
        <TeachersArea v-if="isTeacher"></TeachersArea>
         <!-- <div class="alert alert-danger text-center" style="background:red; color:#fff; font-size:30px; padding:100px 0px; margin-left:-15px; margin-right:-15px;" v-else>
               <p>{{ closeMessage }}</p>
         </div> -->


          <!-- SELEN KALDIRILMASINI İSTEDİ
          <div class="col-md-12 mt-2" v-if="this.$store.state.username === 'fnlplacement' || isTeacher" :class="$style.divPlatformWidth">
          <a :href="`https://report.uesplacement.com/#/login?code=${campusId}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnFnlPlacement]"></div>
            </div>
          </a>
          </div> -->


        <!-- <Collections v-if="isStudent" :isHome="true"></Collections> -->
        <!-- <Collections :isHome="true" v-else-if="isTeacher"></Collections> -->
      </div>
      <div class="mt-3"></div>
    </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

import HeaderCustom from '@/components/HeaderCustom';
// import Chart from '@/components/Chart';
import Homeworks from '@/components/Homeworks';
import Platforms from '@/components/Platforms';
// import Collections from '@/components/Collections';
import TeachersArea from '@/components/TeachersArea';
import InnerLoading from '@/components/InnerLoading';
import servicesUser from '../../services/user';
import GradePhotos from '@/components/GradePhotos';


export default {
    name: 'home',
    data() {
      return {
        ready: false,
        info: null,
        main: null,
        grade: null,
        level: null,
        campusType: null,
        placementHighCode: '',
        placementSecondaryCode: '',
        placementButtonClicked: false,
        startDate: '2021-10-04 00:00:00',
        closeMessage: null,
        interactiveReaders: null,
      };
    },
    methods: {   
      ...mapActions(['getPackages', 'getLmsAssignments', 'getLmsIndAssignments', 'getUserDetails', 'getCollections']),
      ...mapMutations(['setFirst']), 
      placement() {
        if (this.placementButtonClicked === false) {
          this.placementButtonClicked = true;
        } else {
          this.placementButtonClicked = false;
        }
      },
      gointeractiveReaders(id){
          this.routerObj = {
            name: 'scorm',
            params: {
              id: id,
              packageId: this.packageId,
              scormTitle: this.scormTitle,
              scormDesc: this.scormDesc,
              scormCode: this.scormCode,
              timestamp: null,
            },
          };
      }
    },
    async beforeMount() {
      await this.getUserDetails();
      this.ready = false;
      this.level = this.userDetails.organization.filter(x => x.type === 'level')[0].name;
      this.grade = this.userDetails.organization.filter(x => x.type === 'grade');
      this.campusType = this.userDetails.organization.filter(x => x.type === 'campus')[0].school_type;
      const date = await servicesUser.getTimeStamp();
      if(date.data.data >= this.startDate){
        // console.log("Büyük",date.data.data);
      }else{
        this.closeMessage = "Platformlara 4 Ekim 2021 tarihi itibariyle giriş yapabileceksiniz.";
        // console.log('küçük',date.data.data);
      }
      // await this.getUserDetails();
        if (this.$store.state.freeContents.length > 0
      && this.$store.state.assignments.length > 0) { //eslint-disable-line
        //* Daha önceden LMS datası çekildiyse datanın gelmesi beklenmeden true dönülür
        this.ready = true;
        this.getPackages();
        this.getLmsAssignments();
        this.getLmsIndAssignments();
      } else {
        await this.getLmsAssignments();
        await this.getLmsIndAssignments();
        await this.getPackages();
        this.ready = true;
      }
      await this.getCollections();
      
    },
    components: {
      HeaderCustom,
      // Chart,
      Homeworks,
      Platforms,
      // Collections,
      TeachersArea,
      InnerLoading,
      GradePhotos
    },
    props: ['isHome'],
    computed:{
      ...mapState(['userDetails', 'username', 'password', 'collections']),
      isStudent() {
        // this.main = this.userDetails.organization.find(x => x.type === 'main').id;
        // this.main = this.$store.state.userDetails.organization.find(x => x.type === 'main').id;
        const role = this.$store.state.roleType;
        if (role === 'student') {
          return true;
        }
        return false;
      },   
      isTeacher() {
        const role = this.$store.state.roleType;
        if (role === 'teacher') {
          return true;
        }
        return false;
      },
      campusId() {
        const role = this.$store.state.userDetails.organization.find(x => x.type === 'campus');
        return btoa(role.id);
      },
    }
}
</script>
<style src='./style.less' lang='less' module/>