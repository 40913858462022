    <template>
    <div>
      <div class="mt-3 mb-3" style="margin-left: -15px; margin-right: -15px;">
        <a :href="`https://storage.googleapis.com/ueslmscom/fnlenglish/Summer%20Learning%20Pack/Summer%20Learning%20Pack%20Grade%20${grade}.pdf`" target="_blank">
          <div :class="$style.divPlatform">
            <div v-if="grade == 1" :class="[$style.divPlatformBtn, $style.divPlatformBtnCP1]"></div> 
            <div v-else-if="grade == 2" :class="[$style.divPlatformBtn, $style.divPlatformBtnCP2]"></div> 
            <div v-else-if="grade == 3" :class="[$style.divPlatformBtn, $style.divPlatformBtnCP3]"></div> 
            <div v-else-if="grade == 4" :class="[$style.divPlatformBtn, $style.divPlatformBtnCP4]"></div> 
            <div v-else-if="grade == 5" :class="[$style.divPlatformBtn, $style.divPlatformBtnCP5]"></div> 
            <div v-else-if="grade == 6" :class="[$style.divPlatformBtn, $style.divPlatformBtnCP6]"></div> 
            <div v-else-if="grade == 7" :class="[$style.divPlatformBtn, $style.divPlatformBtnCP7]"></div> 
            <div v-else-if="grade == 9" :class="[$style.divPlatformBtn, $style.divPlatformBtnCP9]"></div> 
            <div v-else-if="grade == 10" :class="[$style.divPlatformBtn, $style.divPlatformBtnCP10]"></div> 
          </div>
        </a>
      </div>
    </div>

    
    </template>
    
    <script>

    import { mapState } from 'vuex';
    // import HeaderCustom from '@/components/HeaderCustom';


    
    export default {
      name: 'gradephotos',
      data() {
        return {
          rlpUse: null,
          rlpCode: null,
          grade: '',
          aclas:"$style.divPlatformBtnCP1"
        };
      },
      computed: {
        ...mapState(['userDetails', 'username', 'password']),
        levelName() {
          return this.$store.state.userDetails.organization.find(x => x.type === 'level').name;
        },
        aclass(){
          console.log('$style.divPlatformBtnCP10');
          return 'style.divPlatformBtnCP10'
        },
      },
      props: ['isHome', 'isStudent'],
      beforeMount() {
        this.setData();
      },

      methods: {
        

        async setData() {
    
          if (this.userDetails) {
            this.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;

          }
        },
        // goTeacherLink(id) {
        //   this.$router.push({
        //     name: 'teachersResources',
        //     params: {
        //       id,
        //     },
        //   });
        // },
        // query() {
        //   this.querystingHexaReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('4')}`;
        //   this.querystingKidzReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('23')}`;
        //   this.querystingFnlReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('7')}`;
        //   this.querystingKarnReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('5')}`;
        // },
        
        // RLP() {
        //   // console.log('sdsds')
        //   try {
        //     this.ready = false;
        //     const secretKey = "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N";
        //     const userData = {}
        //     if (this.userDetails) {
        //       userData.username = this.userDetails.email;
        //       userData.first_name = this.userDetails.first_name;
        //       userData.last_name = this.userDetails.last_name;
        //       userData.role_type = this.userDetails.role_type;
        //       userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
        //       userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
        //       userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
        //       userData.class = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
        //       userData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
        //       userData.user_id = this.userDetails.user_id;
        //     }
        //     //  console.log(userData)
    
        //     const textJson = JSON.stringify(userData);
        //     const uriText = decodeURIComponent(textJson)
        //     const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        //     this.ready = true;
        //     window.open('https://service.ueslms.com/#/rlp/?sso=' + btoa(ciphertext));
            
    
        //   } catch (error) {
        //     console.log('err', error);
        //     swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        //     this.ready = true;
        //   }
        // },
    

    
      },
      components: {
        // HeaderCustom,
      },
    };
    </script>
    
    <style src='./style.less' lang='less' module/>
    
    <style scoped>
      a {
        text-decoration: none;
        color: black;
      }
    </style>
    